import { gql } from '@apollo/client';

export const CORE_CHECKOUT = gql`
  fragment CoreCheckout on Checkout {
        shippingAddress {
          address1
          address2
          city
          company
          country
          firstName
          lastName
          phone
          province
          zip
        }
        id
        ready
        requiresShipping
        note
        paymentDueV2 {
          amount
          currencyCode
        }
        webUrl
        orderStatusUrl
        taxExempt
        taxesIncluded
        currencyCode
      
        totalTaxV2 {
          amount
          currencyCode
        }
        lineItemsSubtotalPrice {
          amount
          currencyCode
        }
      
        subtotalPriceV2 {
          amount
          currencyCode
        }
      
        totalPriceV2 {
          amount
          currencyCode
        }
        completedAt
        createdAt
        updatedAt
        email
        lineItems(first: 250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              title
              variant {
                product {
                  id
                  handle
                }
                id
                title
            
                priceV2 {
                  amount
                  currencyCode
                }
                weight
                available: availableForSale
                sku
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                image {
                  id
                  src: url
                  altText
                  width
                  height
                }
                selectedOptions {
                  name
                  value
                }
                unitPrice {
                  amount
                  currencyCode
                }
                unitPriceMeasurement {
                  measuredType
                  quantityUnit
                  quantityValue
                  referenceUnit
                  referenceValue
                }
              }
              quantity
              customAttributes {
                key
                value
              }
            }
          }
        }
      
  }
`;