import './src/styles/global.css'
import "@fontsource/open-sans" // Defaults to weight 400 with normal variant.
import { StoreProvider } from "./src/context/store-context";
import { ApolloProvider } from "@apollo/client";
import React from "react"; 
import client from './src/client';

export const wrapRootElement = ({ element }) => (
 <ApolloProvider client={client}>
    <StoreProvider>{element}</StoreProvider>
</ApolloProvider>
  )