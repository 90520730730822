import fetch from "isomorphic-fetch";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        products: {
          keyArgs: ["sortKey, handle"],
          //keyArgs: false,
          merge(existing = {}, incoming) {
            if (
              JSON.stringify(existing.nodes) === JSON.stringify(incoming.nodes)
            ) {
              existing = {};
            }

           
        if(existing?.nodes) {
          //check for dublicates
        const valueArr = existing?.nodes.map(function (item) {
          return item.__ref;
        });
          const valueArr2 = incoming?.nodes.map(function (item) {
            return item.__ref;
          });
        const found = valueArr.some((r) => valueArr2.indexOf(r) >= 0);
          if(found) {
            existing = {};
          }
      }


            const toCache = {
              ...existing,
              ...incoming,
              nodes: [...(existing?.nodes || []), ...incoming.nodes],
            };

            return toCache;
          },
        },
        articles: {
          edges: {
            keyArgs: ["id"],
            merge(existing = {}, incoming) {
              const toCache = {
                ...existing,
                ...incoming,
                nodes: [...(existing?.nodes || []), ...incoming.nodes],
              };

              return toCache;
            },
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  cache: cache,
  initialState: undefined,
  link: new HttpLink({
    uri: `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/2022-07/graphql.json`,
    headers: {
      "X-Shopify-Storefront-Access-Token":
        process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
      Accept: "application/graphql",
    },
    fetch,
  }),
});

export default client;
