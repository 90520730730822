exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-index-jsx": () => import("./../../../src/pages/aboutus/index.jsx" /* webpackChunkName: "component---src-pages-aboutus-index-jsx" */),
  "component---src-pages-account-index-jsx": () => import("./../../../src/pages/account/index.jsx" /* webpackChunkName: "component---src-pages-account-index-jsx" */),
  "component---src-pages-account-login-form-jsx": () => import("./../../../src/pages/account/LoginForm.jsx" /* webpackChunkName: "component---src-pages-account-login-form-jsx" */),
  "component---src-pages-blog-[handle]-js": () => import("./../../../src/pages/blog/[handle].js" /* webpackChunkName: "component---src-pages-blog-[handle]-js" */),
  "component---src-pages-blog-blog-page-jsx": () => import("./../../../src/pages/blog/BlogPage.jsx" /* webpackChunkName: "component---src-pages-blog-blog-page-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-cart-index-jsx": () => import("./../../../src/pages/cart/index.jsx" /* webpackChunkName: "component---src-pages-cart-index-jsx" */),
  "component---src-pages-catalog-[handle]-js": () => import("./../../../src/pages/catalog/[handle].js" /* webpackChunkName: "component---src-pages-catalog-[handle]-js" */),
  "component---src-pages-catalog-index-js": () => import("./../../../src/pages/catalog/index.js" /* webpackChunkName: "component---src-pages-catalog-index-js" */),
  "component---src-pages-catalog-product-page-jsx": () => import("./../../../src/pages/catalog/ProductPage.jsx" /* webpackChunkName: "component---src-pages-catalog-product-page-jsx" */),
  "component---src-pages-contacts-index-jsx": () => import("./../../../src/pages/contacts/index.jsx" /* webpackChunkName: "component---src-pages-contacts-index-jsx" */),
  "component---src-pages-forget-index-jsx": () => import("./../../../src/pages/forget/index.jsx" /* webpackChunkName: "component---src-pages-forget-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-orders-[name]-js": () => import("./../../../src/pages/my-orders/[name].js" /* webpackChunkName: "component---src-pages-my-orders-[name]-js" */),
  "component---src-pages-my-orders-index-jsx": () => import("./../../../src/pages/my-orders/index.jsx" /* webpackChunkName: "component---src-pages-my-orders-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-profile-index-jsx": () => import("./../../../src/pages/profile/index.jsx" /* webpackChunkName: "component---src-pages-profile-index-jsx" */),
  "component---src-pages-refund-policy-index-jsx": () => import("./../../../src/pages/refund-policy/index.jsx" /* webpackChunkName: "component---src-pages-refund-policy-index-jsx" */),
  "component---src-pages-register-index-jsx": () => import("./../../../src/pages/register/index.jsx" /* webpackChunkName: "component---src-pages-register-index-jsx" */),
  "component---src-pages-reset-index-jsx": () => import("./../../../src/pages/reset/index.jsx" /* webpackChunkName: "component---src-pages-reset-index-jsx" */),
  "component---src-pages-search-index-jsx": () => import("./../../../src/pages/search/index.jsx" /* webpackChunkName: "component---src-pages-search-index-jsx" */),
  "component---src-pages-shipping-policy-index-jsx": () => import("./../../../src/pages/shipping-policy/index.jsx" /* webpackChunkName: "component---src-pages-shipping-policy-index-jsx" */),
  "component---src-pages-terms-of-service-index-jsx": () => import("./../../../src/pages/terms-of-service/index.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-index-jsx" */),
  "component---src-pages-thank-you-index-jsx": () => import("./../../../src/pages/thank-you/index.jsx" /* webpackChunkName: "component---src-pages-thank-you-index-jsx" */)
}

