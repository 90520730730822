import * as React from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { CORE_CHECKOUT } from "../fragment";

const isBrowser = typeof window !== `undefined`;

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  checkout: {
    lineItems: [],
  },
};

const CREATE_CHECKOUT = gql`
${CORE_CHECKOUT}
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
     checkout{
      ...CoreCheckout
     }
    }
  }
`;

const ADD_LINE_ITEMS = gql`
${CORE_CHECKOUT}
mutation checkoutLineItemsAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
  checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
    checkout {
      ...CoreCheckout
     }
              
       
  
  }
}
`

const GET_CURRENT_CHECKOUT = gql`
${CORE_CHECKOUT}
query ($id: ID!){
  node(id: $id) {
    ... on Checkout {
    
        ...CoreCheckout
       
       
    }
  }
}
`

const REMOVE_LINE_ITEMS = gql`
${CORE_CHECKOUT}
mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
  checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
    checkout {
      ...CoreCheckout
     }

  }
}
`
const UPDATE_LINE_ITEMS = gql`
${CORE_CHECKOUT}
mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
  checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
   checkout {
    ...CoreCheckout
   }
  }
}
`


export const StoreContext = React.createContext(defaultValues);
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [loading, setLoading] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
  const [checkoutCreate] = useMutation(CREATE_CHECKOUT);
  const [addLineItems] = useMutation(ADD_LINE_ITEMS);
  const [getCheckout] = useLazyQuery(GET_CURRENT_CHECKOUT);
  const [removeLineItems] = useMutation(REMOVE_LINE_ITEMS);
  const [updateLineItems] = useMutation(UPDATE_LINE_ITEMS);

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(checkout);
  };

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

        
      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          getCheckout(({
            variables: {
              id: existingCheckoutID
            },
            onCompleted: (data) => {
      
              const existingCheckout = data.node;
              if (!existingCheckout.completedAt) {
                setCheckout(existingCheckout);
                return;
              }
            }, onError: (error) => console.log(error)
          }))

        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }


      else {
        checkoutCreate({
        variables: {
          input: {},
        },
        onCompleted: (data) => {
          const newCheckout = data.checkoutCreate.checkout;
          setCheckoutItem(newCheckout);
        },
        onError: (error) => {
          console.log(error.message);
        },
      });
    };
  }

    initializeCheckout();
  }, []);

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true);

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    addLineItems({
      variables: {
        checkoutId: checkout.id,
        lineItems: lineItemsToUpdate,
      },
      onCompleted: (data) => {
        setCheckout(data.checkoutLineItemsAdd.checkout);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 3000);

      },
      onError: (error) => {
        console.log(error.message);
      },
    });

  };

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true);

    removeLineItems({
      variables: {
        checkoutId: checkoutID,
        lineItemIds: [lineItemID],
      },
      onCompleted: (data) => {
        setCheckout(data.checkoutLineItemsRemove.checkout);
        setLoading(false);

      },
      onError: (error) => {
        console.log(error.message);
      },
    });

  };

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true);

    const lineItemsToUpdate = 
      { id: lineItemID, quantity: parseInt(quantity, 10) }
    ;
    updateLineItems({
      variables: {
        checkoutId: checkoutID,
        lineItems: lineItemsToUpdate
      },
      onCompleted: (data) => {
        setCheckout(data.checkoutLineItemsUpdate.checkout);
        setLoading(false);

      },
      onError: (error) => {
        console.log(error.message);
      },
    })
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
